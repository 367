<template>
    <BContainer fluid>
        <h1 class="text-center">{{projects.slide2[1].projectName}}</h1>

        <BRow class="justify-content-center mt-4">
            <div class="col-12 col-xl-6">
                <h4 class="d-none d-md-block text-center">
                    The Stack
                </h4>

                <div class="mt-md-0 mt-2 text-center">
                    <b-badge class="vue-badge">
                        <FontAwesomeIcon
                            :icon="[ 'fab', 'vuejs' ]"
                            class="fa-2x"
                        />
                        Vue
                    </b-badge>

                    <b-badge class="ml-2 css-badge">
                        <FontAwesomeIcon
                            :icon="[ 'fab', 'css3-alt' ]"
                            class="fa-2x"
                        />
                        CSS
                    </b-badge>

                    <b-badge class="ml-2 bootstrap-badge">
                        <FontAwesomeIcon
                            :icon="[ 'fab', 'bootstrap' ]"
                            class="fa-2x"
                        />
                        Bootstrap
                    </b-badge>
                </div>

                <h4 class="d-none d-md-block mt-4 text-center">
                    About
                </h4>

                <p class="mt-md-0 mt-3">
                    For some reason or another, I got very interested in drawing things using just CSS. Maybe it was because 
                    Adobe Illustrator and I didn't get along. But what would be the fun in having a bunch of drawings if I 
                    couldn't use them somewhere?
                </p>

                <p>
                    That is when the Bane Library Package came to life. This way, any drawing that I made, I could pull into 
                    future projects and use them there. I also have some components throughout other projects that would be 
                    cool to make public for others to use, for general display, or to make my life easier in the future.  
                    Those will be future additions to my library package.
                </p>

                <p>
                    One main challenge I'm having is making the drawings dynamic in their display. The plant shelf displayed 
                    is set up in the package to downsize correctly, but once pulled into this project, it has some issues. 
                    Some adjustments have been made on this end to help with the downsizing problems, but there are some 
                    edge cases that could perform better.
                </p>

                <p>
                    Ideally, this wouldn't need to be a step taken every time a CSS drawing is pulled into a project. 
                    I'm looking forward to using this package to further my styling knowledge.
                </p>
            </div>
        </BRow>

        <BRow class="margin-bottom mt-n5 d-flex">
            <div class="col-12 col-md-10 col-xl-12">
                <PlantShelf
                    class="mb-5"
                />
            </div>
        </BRow>

        <ProjectNav
            class="mb-xl-5"
            :previous-project="'Quick Buck'"
            :no-next-nav="true"
            :website-url="projects.slide2[1].url"
        />
    </BContainer>
</template>

<script>
    import Vue from 'vue';
    import {mapState} from 'vuex';

    import ProjectNav from '@/components/ProjectNav';

    import {library} from '@fortawesome/fontawesome-svg-core';

    import {
        faBootstrap,
        faCss3Alt,
        faVuejs,
    } from '@fortawesome/free-brands-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    import {PlantShelf} from 'bane-lib';

    library.add(
        faBootstrap,
        faCss3Alt,
        faVuejs,
    );

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);

    export default {
        name: 'Portfolio',
        components: {
            PlantShelf,
            ProjectNav,
        },
        computed: {
            ...mapState(['projects'])
        }
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "/src/custom"

    .margin-bottom
        margin-bottom: 30rem
</style>
