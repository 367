<template>
    <BRow class="justify-content-center mt-md-5 mt-2 mb-3">
        <router-link
            v-if="!noPreviousNav"
            :to="{name: previousProject}"
            v-b-popover.hover.bottom="'Previous Project'"
        >
            <FontAwesomeIcon
                :icon="[ 'fas', 'arrow-circle-left' ]"
                class="fa-4x mr-2"
            />
        </router-link>

        <a
            v-if="!noLink"
            :href="websiteUrl"
            target="_blank"
            v-b-popover.hover.bottom="'Visit Website'"
        >
            <FontAwesomeIcon
                :icon="[ 'fas', 'link' ]"
                class="fa-4x mr-2"
            />
        </a>

        <router-link
            v-if="!noNextNav"
            :to="{name: nextProject}"
            v-b-popover.hover.bottom="'Next Project'"
        >
            <FontAwesomeIcon
                :icon="[ 'fas', 'arrow-circle-right' ]"
                class="fa-4x mr-2"
            />
        </router-link>
    </BRow>
</template>

<script>
    import Vue from 'vue';

    import {library} from "@fortawesome/fontawesome-svg-core";
    import {
        faArrowCircleLeft,
        faArrowCircleRight,
        faLink
    } from '@fortawesome/free-solid-svg-icons';

    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    library.add(
        faArrowCircleLeft,
        faArrowCircleRight,
        faLink,
    );

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);
    export default {
        name: 'ProjectNav',
        props: {
            nextProject: {
                type: String,
                default: ''
            },
            noLink: {
                type: Boolean,
                default: false
            },
            noNextNav: {
                type: Boolean,
                default: false
            },
            noPreviousNav: {
                type: Boolean,
                default: false
            },
            previousProject: {
                type: String,
                default: ''
            },
            websiteUrl: {
                type: String,
                default: ''
            }
        }
    }
</script>
